import moment from "moment";

export default class Function {
    constructor() {

    }

    normalizeDateFormat(date){
        if(date == undefined || date == null || date == ""){
            return moment().format("DD/MM/YYYY");
        }

        if(date.length > 10){
            date = date.substring(0,10)
        }

        date = date.replaceAll("-","/");
        if(!date.substring(0,4).includes("/")){
            return moment(date).format("DD/MM/YYYY");
        }
        return date
    }

}